html {
  overflow: hidden;
}
body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-family: "Lato", sans-serif;
}
#root {
  height: 100%;
  width: 100%;
  display: inline-block;
}
header {
  float: left;
  width: 100%;
  height: 4rem;
  background-color: #49b9d2;
  position: relative;
  z-index: 1;
  box-shadow: 0px 2px 10px -5px rgb(0 0 0 / 75%);
}
main {
  width: 100vw;
  height: calc(100vh - 5.5rem);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  float: left;
}
footer {
  width: 100%;
  text-align: center;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.7rem;
  background-color: #ffffff;
  float: left;
}
footer a,
footer a:hover,
footer a:active {
  color: #027678;
  text-decoration: none;
  font-weight: 600;
}
.tooltip-inner {
  max-width: max-content;
}
.hidden {
  display: none !important;
}
.btn-small {
  width: 2.125em;
  height: 2.125em;
  content: "";
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: #f9f9f9;
  border-radius: 4px;
  background-clip: padding-box;
  cursor: pointer;
  position: absolute;
}
.btn-small.export {
  background-image: url(../images/export.png);
  top: 0.5rem;
  right: 0.5rem;
}
.btn-small.screenshot {
  background-image: url(../images/screenshot.png);
  top: 0.5rem;
  right: 3.25rem;
}
.btn-small.reset-zoom {
  background-image: url(../images/center.png);
  bottom: 0.5rem;
  right: 0.5rem;
}
.container.map .range-container {
  display: inline-block;
  width: 15rem;
  height: 1rem;
  z-index: 0;
  position: absolute;
  transform: rotate(270deg);
  right: -6rem;
  top: 50%;
}
.container.map .range-container:before {
  display: inline-block;
  content: "";
  background-image: url(../images/transparency.png);
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  right: -2rem;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}
.container.map .range {
  width: 15rem;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #f9f9f9;
    border-radius: 5px;
    /*height: 1.2rem;*/
  }

  input[type="range"]::-webkit-slider-runnable-track {
    border-radius: 5px;
    /*height: 10px;
    /*-webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;*/
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 10px;
    -webkit-appearance: none;
    cursor: default;
    background: #7b868d;
    position: relative;
    z-index: 2;
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #7b868d;
}
input[type="range"]::-moz-range-track {
  background-color: #f9f9f9;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #7b868d;
}
input[type="range"]::-ms-fill-upper {
  background-color: #f9f9f9;
}

/* CSS Tooltip*/
*[css-tooltip] {
  position: relative;
  z-index: 1;
}
*[css-tooltip]::before,
*[css-tooltip]::after {
  content: attr(css-tooltip);
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%);
  bottom: calc(100% + 8px);
  right: auto;
  left: 50%;
  display: none;
}
*[css-tooltip]:hover:before,
*[css-tooltip]:active:before {
  content: "\25BC";
  color: #000000cf;
  visibility: visible;
  position: absolute;
  z-index: 20;
  opacity: 0.9;
  bottom: calc(100% - 3px);
  font-family: var(--open-sans);
  font-size: var(--ten);
  font-weight: 500;
  white-space: pre;
  line-height: 15px;
  display: inline-block;
}
*[css-tooltip]:hover:after,
*[css-tooltip]:active:after {
  font-family: var(--open-sans);
  font-size: var(--twelve);
  font-weight: 500;
  line-height: 15px;
  color: #fff;
  visibility: visible;
  position: absolute;
  white-space: pre;
  z-index: 20;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 4px #222;
  box-shadow: 0 0 10px #222;
  background-color: #000000cf;
  opacity: 1;
  height: auto;
  min-height: 13px;
  max-width: 260px;
  padding: 7px;
  display: inline-block;
}

/*///////////////////  LOADING  ///////////////////*/
.wrap-shadow {
  display: block;
  background: #000;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}
/*body.loading-indicator .wrap-shadow {
  display: block;
}*/
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes uil-default-anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes uil-default-anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.uil-default-css {
  position: absolute;
  background: none;
  width: 200px;
  height: 200px;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  transform: scale(0.5);
}

.uil-default-css > div {
  position: absolute;
  border-radius: 10px;
  top: 80px;
  left: 93px;
  width: 14px;
  height: 40px;
  background: #ffffff;
}

.uil-default-css > div.loading-text {
  position: absolute;
  background: none;
  width: 310px;
  height: 200px;
  left: calc(50% - 155px);
  text-align: center;
  top: 200px;
  font-size: 38px;
  color: #cfd0d3;
}

.uil-default-css > div:nth-of-type(1) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
  -webkit-transform: rotate(0deg) translate(0, -60px);
  transform: rotate(0deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(2) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.4166666666666667s;
  animation-delay: -0.4166666666666667s;
  -webkit-transform: rotate(30deg) translate(0, -60px);
  transform: rotate(30deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(3) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.33333333333333337s;
  animation-delay: -0.33333333333333337s;
  -webkit-transform: rotate(60deg) translate(0, -60px);
  transform: rotate(60deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(4) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
  -webkit-transform: rotate(90deg) translate(0, -60px);
  transform: rotate(90deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(5) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.16666666666666669s;
  animation-delay: -0.16666666666666669s;
  -webkit-transform: rotate(120deg) translate(0, -60px);
  transform: rotate(120deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(6) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: -0.08333333333333331s;
  animation-delay: -0.08333333333333331s;
  -webkit-transform: rotate(150deg) translate(0, -60px);
  transform: rotate(150deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(7) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(180deg) translate(0, -60px);
  transform: rotate(180deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(8) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.08333333333333337s;
  animation-delay: 0.08333333333333337s;
  -webkit-transform: rotate(210deg) translate(0, -60px);
  transform: rotate(210deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(9) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.16666666666666663s;
  animation-delay: 0.16666666666666663s;
  -webkit-transform: rotate(240deg) translate(0, -60px);
  transform: rotate(240deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(10) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-transform: rotate(270deg) translate(0, -60px);
  transform: rotate(270deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(11) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.33333333333333337s;
  animation-delay: 0.33333333333333337s;
  -webkit-transform: rotate(300deg) translate(0, -60px);
  transform: rotate(300deg) translate(0, -60px);
}

.uil-default-css > div:nth-of-type(12) {
  -webkit-animation: uil-default-anim 1s linear infinite;
  animation: uil-default-anim 1s linear infinite;
  -webkit-animation-delay: 0.41666666666666663s;
  animation-delay: 0.41666666666666663s;
  -webkit-transform: rotate(330deg) translate(0, -60px);
  transform: rotate(330deg) translate(0, -60px);
}

/*Modals*/
.modal-content {
  background-color: #f9f9f9;
}
.modal-header {
  border: none;
  text-align: center;
}
.modal-title {
  color: #7b868d;
  width: 100%;
}
.modal-body {
  color: #707070;
  padding: 1rem 2rem;
}
.modal-footer {
  border: none;
}
.btn-close {
  color: #fff;
  background-color: #7b868d;
  border-color: #7b868d;
}
.btn-close:hover,
.btn-close:active {
  color: rgba(255, 255, 255, 0.7);
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  border: none;
  outline: none;
}

/*///////////////////  NAVIGATION  ///////////////////*/
.brio-header .navbar {
  padding: 0rem 1rem;
}
.home-hidden {
  margin: 0 !important;
  padding: 0 !important;
}
/*Brand*/
.brio-header .navbar .navbar-brand {
  margin-right: 3rem;
  padding: 0;
  line-height: 1rem;
}
.brio-header .logo-brio-climate-platform {
  margin-right: 0;
  display: inline-block;
  width: auto;
  padding: 1rem 0;
}
.brio-header .logo-brio-climate-platform span {
  width: 100%;
  float: left;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #0074a9;
}
.brio-header .logo-brio-climate-platform span:last-child {
  font-size: 1rem;
  font-weight: 400;
  color: #76b5c8;
}
.brio-header .logo-brio-climate-platform img {
  height: 2rem;
}
.brio-header .navbar-dark .navbar-nav .nav-link:hover,
.brio-header .navbar-dark .navbar-nav .nav-link:active,
.brio-header .navbar-dark .navbar-nav .nav-link.active:hover,
.brio-header .navbar-dark .navbar-nav .nav-link.active:active {
  color: #0d7890;
}
.brio-header .navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
  font-weight: 400;
}
.navbar-dark .navbar-right.navbar-nav .nav-link.coi-logo {
  background-image: url("../images/logo-coi-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 10rem;
  height: 4rem;
}
.navbar-dark .navbar-right.navbar-nav .nav-link.coi-logo:hover,
.navbar-dark .navbar-right.navbar-nav .nav-link.coi-logo:active {
  background-image: url("../images/logo-coi.png");
}
.navbar-dark .navbar-right.navbar-nav .nav-link.meteofrance-logo {
  background-image: url("../images/logo-meteofrance-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 8rem;
  height: 3.2rem;
  margin: 0.4rem 2rem;
}
.navbar-dark .navbar-right.navbar-nav .nav-link.meteofrance-logo:hover,
.navbar-dark .navbar-right.navbar-nav .nav-link.meteofrance-logo:active {
  background-image: url("../images/logo-meteofrance.png");
}
.navbar-dark .navbar-right.navbar-nav .nav-link.afd-logo {
  background-image: url("../images/logo-afd-white.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 10rem;
  height: 3.2rem;
  margin: 0.5rem 0 0.3rem 0;
}
.navbar-dark .navbar-right.navbar-nav .nav-link.afd-logo:hover,
.navbar-dark .navbar-right.navbar-nav .nav-link.afd-logo:active {
  background-image: url("../images/logo-afd.png");
}
.lang-button.dropdown {
  display: inline-block;
  height: 3.2rem;
  margin: auto;
  line-height: 3.2rem;
  margin-left: 2.5rem;
}
.lang-button.dropdown .btn-primary {
  vertical-align: middle;
  display: inline-block;
  margin: auto;
  padding: 0.2rem 0.75rem 0.2rem 2.5rem;
  font-size: 0.8rem;
  background-color: transparent;
  border: none;
}
.lang-button.dropdown .btn-primary.dropdown-toggle::after {
  margin-left: 0.5rem;
}
.lang-button.dropdown .btn-primary.dropdown-toggle::before {
  content: "";
  width: 1.3rem;
  height: 0.866rem;
  position: absolute;
  background-image: url("../images/fr.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  left: 0.5rem;
  top: 1.3rem;
}
.lang-button.lang-fr.dropdown .btn-primary.dropdown-toggle::before {
  background-image: url("../images/fr.png");
}
.lang-button.lang-en.dropdown .btn-primary.dropdown-toggle::before {
  background-image: url("../images/en.png");
}
.lang-button.dropdown .btn-primary:focus {
  outline: 0 !important;
  box-shadow: none !important;
  color: #ffffff !important;
  background-color: transparent !important;
}
.lang-button.dropdown .btn-primary:hover,
.lang-button.dropdown .btn-primary:active {
  outline: 0 !important;
  box-shadow: none !important;
  color: #0d7890 !important;
  background-color: transparent !important;
}
.lang-button.dropdown .dropdown-menu {
  padding: 0;
  line-height: normal;
  min-width: auto;
  border-radius: 0.25rem;
  top: 3rem;
  right: 0.9rem;
  left: auto;
}
.lang-button.dropdown .dropdown-menu .dropdown-item {
  display: inline-block;
  margin: auto;
  padding: 0.45rem 0.75rem 0.45rem 2.5rem;
  color: #7b868d;
  position: relative;
  font-size: 0.8rem;
}
.lang-button.dropdown .dropdown-menu .dropdown-item:hover:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.lang-button.dropdown .dropdown-menu .dropdown-item:hover:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.lang-button.dropdown .dropdown-menu .dropdown-item:hover,
.lang-button.dropdown .dropdown-menu .dropdown-item:active {
  background-color: #e5e5e5;
}
.lang-button.dropdown .dropdown-menu .dropdown-item.active,
.lang-button.dropdown .dropdown-menu .dropdown-item.active:hover,
.lang-button.dropdown .dropdown-menu .dropdown-item.active:active {
  background-color: #39adc7;
  pointer-events: none;
  color: #ffffff;
}
.lang-button.dropdown .dropdown-menu .dropdown-item::before {
  content: "";
  width: 1.4rem;
  height: 0.93rem;
  position: absolute;
  background-image: url("../images/fr.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  left: 0.5rem;
  top: 0.5rem;
}
.lang-button.dropdown .dropdown-menu .dropdown-item.drop-fr::before {
  background-image: url("../images/fr.png");
}
.lang-button.dropdown .dropdown-menu .dropdown-item.drop-en::before {
  background-image: url("../images/en.png");
}
/*Menu*/
.navbar-center > .nav-link,
.navbar-center > .nav-item {
  margin: 0 0.5rem;
}
.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #aeedf1;
  font-weight: 700;
}
/*Dropdowm*/
.dropdown-divider {
  margin: 0;
}
.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item:active {
  background-color: #e5e5e5;
}
.navbar-center > .nav-item .dropdown-item.active {
  background-color: #e5e5e5;
  color: #a4a4a4;
}
.dropdown-item-text {
  padding: 0.5rem 1.5rem;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.navbar-center > .nav-item .dropdown-item.disabled,
.navbar-center > .nav-item .dropdown-item:disabled,
.navbar-center > .nav-item .dropdown-item-text[disabled] {
  color: #bfbfbf;
}

/*Responsive navbar nav*/
.navbar-dark .navbar-toggler {
  color: #ffffff;
  font-size: 1.75rem;
  border: none !important;
  outline: 0 !important;
}
.navbar-dark .navbar-toggler .navbar-toggler-icon {
  background-image: url(../images/close_white.png);
}
.navbar-dark .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url(../images/menu_white.png);
}

/*///////////////////  Home page  ///////////////////*/
.home-container-image,
.home-image {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.home-image img {
  display: inline-block;
  height: auto;
  width: calc(100vw - 10rem);
}
.home-image h1 {
  display: inline-block;
  vertical-align: top;
  margin-top: 8rem;
  width: 18rem;
  right: 6rem;
  position: absolute;
}
.home-image h1 span {
  width: 100%;
  float: left;
  font-size: 3rem;
  font-weight: 600;
  color: #0074a9;
}
.home-image h1 span:last-child {
  display: inline-block;
  font-size: 2.3rem;
  font-weight: 400;
  color: #76b5c8;
}
/*///////////////////  Climate Change Risk  ///////////////////*/
/*Header CCR buttons*/
.container-header {
  float: left;
  height: 6rem;
  width: 100%;
}
.container-header .header {
  display: inline-block;
  width: 100%;
}
.container-header .header-regions {
  position: relative;
  z-index: 0;
  height: 2.5rem;
  padding: 0 0.5rem;
  top: -0.2rem;
  display: inline-block;
  width: 100%;
}
.container-header .header-regions .nav.nav-pills {
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}
.container-header .header-regions .nav.nav-pills .nav-item {
  flex-grow: 1;
  margin: 0 0.25rem 0 0;
  height: 100%;
}
.container-header .header-regions .nav.nav-pills .nav-item:last-child {
  margin: 0;
}
.header-regions .nav.nav-pills .nav-item .nav-link:not(.global-button) {
  border-radius: 0;
  background-color: #aeedf1;
  color: #7b868d;
  padding: 0.6rem 1rem;
  text-align: center;
}
.header-regions .nav-item .nav-link.global-button {
  border-radius: 0;
  background-color: #809097;
  color: #fff;
  padding: 0.6rem 1rem 0.6rem 2rem;
  text-align: center;
  text-transform: capitalize;
}
.header-regions .nav-item .nav-link.global-button::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(../images/icon-region.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  margin-left: -2rem;
}

.header-regions .nav.nav-pills .nav-item .nav-link.active {
  color: #fff;
  background-color: #f8bc67;
  cursor: default;
}
.header-regions.aga .nav.nav-pills .nav-item #btn_aga.nav-link:not(.active),
.header-regions.com .nav.nav-pills .nav-item #btn_com.nav-link:not(.active),
.header-regions.reu .nav.nav-pills .nav-item #btn_reu.nav-link:not(.active),
.header-regions.mdg .nav.nav-pills .nav-item #btn_mdg.nav-link:not(.active),
.header-regions.mau .nav.nav-pills .nav-item #btn_mau.nav-link:not(.active),
.header-regions.rod .nav.nav-pills .nav-item #btn_rod.nav-link:not(.active),
.header-regions.stb .nav.nav-pills .nav-item #btn_stb.nav-link:not(.active),
.header-regions.sey .nav.nav-pills .nav-item #btn_sey.nav-link:not(.active) {
  background-color: #39adc7;
  cursor: pointer;
  color: #ffffff;
}
.header-regions .nav.nav-pills .nav-item .nav-link:not(.active):hover,
.header-regions .nav.nav-pills .nav-item .nav-link:not(.active):active {
  background-color: #39adc7;
  cursor: pointer;
  color: #ffffff;
}
.container-header .header-variables-export {
  display: inline-block;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 7px 0px rgb(0 0 0 / 20%);
  height: 3rem;
  padding: 0.25rem 0.5rem;
}
.container-header .header-variables {
  display: flex;
  float: left;
  width: calc(100% - 2.8rem);
  padding: 0;
  height: 2.5rem;
  background-color: #f9f9f9;
  border-radius: 0.2rem;
}
.container-header .btn-exports {
  float: right;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.container-header .btn-exports .dropdown-toggle {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  padding: 0.275rem;
}
.container-header .btn-exports .dropdown-toggle::after {
  display: none;
}
.container-header .btn-exports .dropdown-header {
  background-color: transparent;
  color: #747474;
  font-size: 0.8rem;
}
.container-header .btn-exports .dropdown-menu {
  left: -1rem !important;
  box-shadow: 0px 4px 7px 0px rgb(0 0 0 / 20%);
}
.container-header
  .btn-exports
  .dropdown-menu.show
  .dropdown-item:not(.disabled):focus,
.container-header
  .btn-exports
  .dropdown-menu.show
  .dropdown-item:not(.disabled):hover,
.container-header
  .btn-exports
  .dropdown-menu.show
  .dropdown-item:not(.disabled):active {
  background-color: #f2f2f2;
  color: #404040;
}

.header .dropdown {
  flex-grow: 1;
  border-right: solid 1px #a9a9a9;
}
.header .dropdown .btn-primary {
  color: #7b868d;
  background-color: #f9f9f9;
  border: none;
  text-align: left;
  padding: 0.2rem 0.75rem 0.2rem 2rem;
  border-radius: initial;
  margin: 0.3rem 2rem 0.3rem 0;
  flex: none;
  width: calc(100% - 2rem);
  position: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #7b868d;
  background-color: #f9f9f9;
  border-color: #a9a9a9;
}
.header .dropdown:last-child {
  border-right: none;
}
.header .dropdown .btn-primary.dropdown-toggle::before {
  display: inline-block;
  content: "";
  width: 1.4rem;
  height: 1.4rem;
  background-image: url(../images/season.png);
  position: relative;
  left: -1rem;
  top: -0.1rem;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}
.header .dropdown.season .btn-primary.dropdown-toggle::before {
  background-image: url(../images/season.png);
}
.header .dropdown.variable .btn-primary.dropdown-toggle::before {
  background-image: url(../images/variable.png);
}
.header .dropdown.model .btn-primary.dropdown-toggle::before {
  background-image: url(../images/model.png);
}
.header .dropdown.scenario .btn-primary.dropdown-toggle::before {
  background-image: url(../images/scenario.png);
}
.header .dropdown.period .btn-primary.dropdown-toggle::before {
  background-image: url(../images/period.png);
}
.header .dropdown.anomaly .btn-primary.dropdown-toggle::before {
  background-image: url(../images/anomaly.png);
}

.header .dropdown .btn-primary.dropdown-toggle::after {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.header .dropdown .btn-primary:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.header .dropdown-menu {
  z-index: 1001;
  padding: 0;
  border-radius: 0;
}
.header .dropdown-menu .dropdown-item:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}
.header .dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.header .dropdown-menu a::first-letter {
  text-transform: capitalize;
}
.header .dropdown-menu.show .dropdown-item {
  color: #7b868d;
  padding: 0.5rem 1.5rem;
}
.header .dropdown-menu > .dropdown-divider:last-child {
  display: none;
}
.header .dropdown-menu.show .dropdown-item:focus,
.header .dropdown-menu.show .dropdown-item:hover,
.header .dropdown-menu.show .dropdown-item:active {
  background-color: #e5e5e5;
}
.header .dropdown-menu.show .dropdown-header {
  text-align: center;
  padding: 0.75rem 1.5rem 0.5rem;
}
.header .dropdown-menu.show .dropdown-item.active {
  text-decoration: none;
  background-color: #aeedf1;
  pointer-events: none;
}
.header .dropdown-menu.show .dropdown-item.disabled {
  opacity: 0.6;
  pointer-events: auto;
  background-color: transparent !important;
  color: #7b868d;
}

.header .variable .dropdown-menu.show .dropdown-cat.show {
  background-color: #e5e5e5;
  /*position: relative;*/
}
.variable .dropdown-menu.show .dropdown-cat > .btn-success.dropdown-toggle {
  padding: 0.5rem 1.5rem;
  color: #7b868d;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  display: inline-block;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  border-radius: 0;
}
.variable
  .dropdown-menu.show
  .dropdown-cat
  > .btn-success.dropdown-toggle:after {
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  margin-top: 0.55rem;
  right: 1.3rem;
}
.variable
  .dropdown-menu.show
  .dropdown-cat.active
  > .btn-success.dropdown-toggle {
  background-color: #aeedf1;
}
.variable
  .dropdown-menu.show
  .dropdown-cat
  > .btn-success.dropdown-toggle:hover,
.variable
  .dropdown-menu.show
  .dropdown-cat.active
  > .btn-success.dropdown-toggle:hover,
.variable
  .dropdown-menu.show
  .dropdown-cat
  > .btn-success.dropdown-toggle:active,
.variable
  .dropdown-menu.show
  .dropdown-cat.active
  > .btn-success.dropdown-toggle:active {
  background-color: #e5e5e5;
}

.variable .dropdown-menu.show .dropdown-cat > .dropdown-menu {
  top: -100% !important;
  left: 100% !important;
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
}

/*Map/Graphic Container*/
.container-body {
  display: inline-block;
  height: calc(100vh - 11.5rem);
  width: 100vw;
}
.body {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.container.map {
  height: 100%;
  float: left;
  padding: 0 0 0 0.5rem;
  position: relative;
}

.container.map.sync-map {
  width: 50%;
  transition: width 0.5s;
}
.container.map.full-map {
  width: calc(100vw - 6rem);
  transition: width 0.5s;
  max-width: none;
}
.container.map .map-container {
  height: 100%;
  width: 100%;
  font: initial;
  font-family: "Lato", sans-serif;
  z-index: 0;
}

.container.graphic {
  height: 100%;
  float: right;
  max-width: none;
  padding: 0;
}

.container.graphic.opened-graph {
  width: 50%;
  transition: width 0.5s;
}
.container.graphic.closed-graph {
  width: 6rem;
  transition: width 0.5s;
  cursor: pointer;
}
.container.graphic .container-icon-open-close {
  width: 1.25rem;
  height: 94%;
  margin: 0 0.3rem;
  border-left: solid 2px #a9a9a9;
  cursor: pointer;
  position: relative;
  float: left;
  top: 3%;
}
.container.graphic.closed-graph .container-icon-open-close {
  border-left: none;
  border-right: solid 2px #a9a9a9;
}
.container.graphic.opened-graph .container-icon-open-close:hover,
.container.graphic.opened-graph .container-icon-open-close:active {
  border-left: solid 2px #777777;
}
.container.graphic.closed-graph:hover .container-icon-open-close,
.container.graphic.closed-graph:active .container-icon-open-close,
.container.graphic.closed-graph .container-icon-open-close:hover,
.container.graphic.closed-graph .container-icon-open-close:active {
  border-right: solid 2px #777777;
}
.container.graphic .container-icon-open-close:hover span.icon-close,
.container.graphic .container-icon-open-close:active span.icon-close {
  border-left: 0.5rem solid #777777;
}
.container.graphic.closed-graph:hover span.icon-open,
.container.graphic.closed-graph:active span.icon-open,
.container.graphic .container-icon-open-close:hover span.icon-open,
.container.graphic .container-icon-open-close:active span.icon-open {
  border-right: 0.5rem solid #777777;
}

.container-icon-open-close span {
  display: inline-block;
  height: 0;
  width: 0;
  position: absolute;
  top: calc(50% - 0.2rem);
  left: 0.25rem;
  border-top: 0.6rem solid transparent;
  border-bottom: 0.6rem solid transparent;
}
span.icon-close {
  border-left: 0.5rem solid #a9a9a9;
}
span.icon-open {
  border-right: 0.5rem solid #a9a9a9;
}
.opened-graph .container-icon-graphic,
.opened-graph span.icon-open,
.closed-graph span.icon-close {
  display: none;
}

.container.graphic .graphic {
  display: inline-block;
  height: 100%;
}
.container.graphic.opened-graph .graphic {
  width: calc(100% - 1.85rem);
  overflow: hidden;
  transition: width 0.5s;
  padding: 1rem 1rem 1rem 0;
  position: relative;
}
.container.graphic.closed-graph .graphic {
  width: 0;
  overflow: hidden;
  transition: width 0.5s, padding 0.5s;
  padding: 2rem 0;
}

.container.graphic #title_graphs {
  font-size: 1.2rem;
  text-align: left;
  width: calc(100% - 1rem);
  overflow: hidden;
  height: 2rem;
  color: #49b9d2;
  padding-left: 2rem;
}
.container.graphic .graphic .btn-graphs-island.btn-primary {
  color: #7b868d;
  background-color: #ffffff;
  border-color: #efefef;
  font-size: 0.8rem;
  position: absolute;
  right: 2rem;
  top: 1rem;
  padding: 0.3rem 0.5rem;
  outline: none;
  box-shadow: none;
}
.container.graphic .graphic .btn-graphs-island.btn-primary:before {
  display: inline-block;
  content: "";
  width: 1rem;
  height: 1rem;
  background-image: url(../images/graphic.png);
  position: relative;
  background-size: contain;
  vertical-align: top;
  margin-right: 0.5rem;
  background-repeat: no-repeat;
  opacity: 1;
}
.btn-graphs-island.btn-primary:not(.disabled):not([disabled]):hover,
.btn-graphs-island.btn-primary:not(.disabled):not([disabled]):active {
  color: #454545;
  border-color: #ccd0d3;
}

.container-icon-graphic {
  float: left;
  width: 4.15rem;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.container-icon-graphic img {
  display: inline-block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 0.5rem;
  top: calc(50% - 0.6em);
}

.map-container .type-sector {
  width: 15rem;
  position: absolute;
  z-index: 500;
  top: 0.5rem;
  left: 0.5rem;
}
.map-container .dropdown.type-sector .btn-primary {
  color: #7b868d;
  background-color: #f9f9f9;
  text-align: left;
  padding: 0.3rem 1rem 0.4rem 0;
  border: 2px solid #f9f9f9;
  background-clip: padding-box;
  border-radius: 0;
  margin: 0;
  flex: none;
  width: 100%;
  height: 2.125rem;
  line-height: 1rem;
}
.map-container .dropdown.type-sector .btn-primary.dropdown-toggle::before {
  display: inline-block;
  content: "";
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  left: -1rem;
  top: -0.1rem;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}
.map-container .dropdown.type-sector .btn-primary.dropdown-toggle::after {
  float: right;
  position: relative;
  top: 0.5rem;
}
.map-container .dropdown-menu {
  padding: 0;
  z-index: 500;
  margin-top: 0 !important;
}
.map-container .dropdown-menu.show .dropdown-item {
  color: #7b868d;
  padding: 0.5rem 1.5rem;
  text-transform: capitalize;
}
.map-container .dropdown-menu.show .dropdown-item:focus,
.map-container .dropdown-menu.show .dropdown-item:hover,
.map-container .dropdown-menu.show .dropdown-item:active {
  background-color: #e5e5e5;
}
.map-container .dropdown-menu.show .dropdown-item.active {
  color: #7b868d;
  text-decoration: none;
  background-color: #aeedf1;
  cursor: default;
}
.map-container .dropdown .btn-primary:focus {
  box-shadow: none !important;
}
.map-container button.btn-global-val {
  width: auto;
  position: absolute;
  z-index: 500;
  top: 0.5rem;
  left: 16rem;
  color: #7b868d;
  background-color: #f9f9f9;
  text-align: left;
  padding: 0.3rem 1rem 0.4rem 1rem;
  border: 3px solid #f9f9f9;
  background-clip: padding-box;
  border-radius: 0px;
  margin: 0px;
  flex: 0 0 auto;
  height: 2.125rem;
  line-height: 1rem;
  outline: none;
}
.map-container button.btn-global-val > span {
  display: inline-block;
  width: 1.5rem;
  height: 1rem;
  vertical-align: bottom;
  margin-left: 1rem;
  border: 1px solid #bebebe;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.map-container button.btn-global-val:focus {
  box-shadow: none;
}
button.btn-global-val.btn-primary:not(:disabled):not(.disabled).active {
  color: #7b868d;
  border-color: #49b9d2;
}
.map-container .export {
  position: absolute;
  z-index: 500;
  top: 0.5rem;
  right: 0.5rem;
}
.map-container .export.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.map-container .reset-zoom {
  position: absolute;
  z-index: 500;
  bottom: 0.5rem;
  right: 0.5rem;
}
.clone-map.leaflet-container {
  background: #ffffff;
}

.map-container.leaflet-container
  .leaflet-hover-popup
  a.leaflet-popup-close-button {
  display: none;
}
.map-container .leaflet-popup-content-wrapper {
  background: #49b9d2;
  color: #ffffff;
  opacity: 0.9;
}
.map-container .leaflet-popup-tip {
  background: #49b9d2;
  color: #ffffff;
}
.map-container .vector-map.leaflet-interactive.active {
  stroke: rgb(73, 185, 210);
  stroke-opacity: 1;
  stroke-width: 2.5;
  stroke-dasharray: 0;
}
.map-container .leaflet-popup-content-wrapper .leaflet-popup-content {
  font-size: 0.96rem;
}
.map-container
  .leaflet-hover-popup
  .leaflet-popup-content-wrapper
  .leaflet-popup-content {
  margin: 0.3rem 0.9rem;
  font-size: 0.9rem;
}
.map-container.leaflet-container a.leaflet-popup-close-button {
  color: #ffffff;
}
.map-container.leaflet-container a.leaflet-popup-close-button:hover,
.map-container.leaflet-container a.leaflet-popup-close-button:active {
  color: rgba(255, 255, 255, 0.7);
}
/*Leaflet Map*/
.map-container .leaflet-bottom.leaflet-right {
  bottom: 0;
  display: none;
}
.map-container .leaflet-left .leaflet-control {
  margin: 1rem 0 0 1rem;
}
.map-container .leaflet-control-container .leaflet-top {
  top: 0.5rem;
  right: 6.125rem;
  z-index: 450;
  margin: 0;
  display: inline-block;
}
.map-container .leaflet-control-container .leaflet-bar {
  margin-top: 0;
  margin-right: 0;
  float: right;
  clear: none;
}
.map-container .leaflet-control-container .leaflet-bar a:first-child {
  float: right;
  border-radius: 0px 2px 2px 0px;
}
.map-container .leaflet-control-container .leaflet-bar a:last-child {
  float: right;
  border-radius: 2px 0px 0px 2px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-width: 0px 1px 1px 0px;
}
.map-container .leaflet-control-container .leaflet-control-layers {
  display: inline-block;
  margin-top: 0;
  margin-right: 0.5rem;
  clear: none;
}
.map-container.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px;
  height: 30px;
  background-size: 70%;
}
.map-container.leaflet-touch .leaflet-bar a {
  background-color: #f9f9f9;
}

.map-container.leaflet-touch .leaflet-interactive.no-click {
  cursor: grab;
}
.leaflet-dragging .map-container.leaflet-touch .leaflet-interactive.no-click {
  cursor: grabbing;
}

/*Legends Map*/
.clone-legend.legend {
  display: inline-block;
  width: auto;
  height: auto;
  color: #000000;
  background-color: #f9f9f9;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
}

.map-container .legend {
  position: absolute;
  z-index: 401;
  bottom: 0.5rem;
  left: 0.5rem;
  width: auto;
  height: auto;
  color: #000000;
  background-color: #f9f9f9;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-radius: 4px;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
}
.clone-legend.legend .unit,
.map-container .legend .unit {
  display: block;
  text-align: center;
  margin-bottom: 0.6rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.clone-legend.legend .colors,
.map-container .legend .colors {
  float: left;
  width: calc(1.5rem + 2px);
  height: auto;
  border: 1px solid #bebebe;
}
.clone-legend.legend .colors div,
.map-container .legend .colors div {
  height: 1rem;
  margin: 0;
  width: 1.5rem;
}
.clone-legend.legend .texts,
.map-container .legend .texts {
  font-size: 0.7rem;
  font-weight: 600;
  float: left;
  width: auto;
  height: auto;
  line-height: 0;
  position: relative;
}
.clone-legend.legend .texts div,
.map-container .legend .texts div {
  height: 1rem;
  margin: 0;
  width: auto;
  margin-left: 0.7rem;
}
.clone-legend.legend .texts div::before,
.map-container .legend .texts div::before {
  content: "";
  height: 1.3rem;
  width: 0.3rem;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #bebebe;
  position: absolute;
  display: block;
  left: 0;
}
.clone-legend.legend .texts div:last-child,
.map-container .legend .texts div:last-child {
  height: 0;
}
.clone-legend.legend img,
.map-container .legend img {
  display: inline-block;
  height: 3.5rem;
  margin: auto;
}

.clone-legend.legend {
  position: absolute;
  z-index: 44444444;
}

/*png*/
.png-container {
  background-color: transparent;
  position: relative;
  /* position: fixed; */
  /* top: -160px; */
  /* left: 0; */
  /* background-color: black; */
  /* transform: scale(0.5); */
  /* z-index: 1;*/
}
.png-container .clone-legend.legend {
  bottom: 20px;
  float: left;
  left: 17px;
  padding-bottom: 0;
}
.png-container .clone-legend.legend .texts,
.png-container .clone-legend.legend .unit {
  font-size: 1.3rem;
}
.png-container .clone-legend.legend .colors {
  width: calc(4rem + 2px);
  border: 1px solid #4a4a4a;
}
.png-container .clone-legend.legend .colors div {
  height: 3rem;
  width: 4rem;
}
.png-container .clone-legend.legend .texts div {
  height: 3rem;
  margin-left: 1.5rem;
}
.png-container .clone-legend.legend .texts div::before {
  height: 1.3rem;
  width: 0.3rem;
  border-width: 2px 0 0 0;
  border-color: #858585;
}

.png-container .clone-map.leaflet-container {
  float: right;
  background-color: transparent;
}

/*Dygrahs*/
.container-graphic {
  width: 100%;
  height: calc(50% - 1rem);
  padding: 1.5rem 0 0 0;
  float: left;
  /*overflow: hidden;*/
  position: relative;
}
.container-graphic:first-child {
  margin-bottom: 0.5rem;
}
.container-graphic.loading-charts {
  height: calc(100% - 1rem);
  text-align: center;
}
.container-graphic.loading-charts h2 {
  position: relative;
  top: calc(50% - 3rem);
}
.container-graphic .export {
  position: absolute;
  right: 8rem;
  top: 3rem;
  width: 2rem;
  height: 2rem;
  border: none;
  background-color: #ffffff;
  background-size: 60%;
}
.container-graphic .export.exp-year {
  right: 10rem;
}
.monthly-graph {
  width: calc(100% - 7rem);
  height: 100%;
  float: left;
  /*overflow: hidden;*/
  position: relative;
}
.yearly-graph {
  width: calc(100% - 9.5rem);
  height: 100%;
  float: left;
  /*overflow: hidden;*/
  position: relative;
}
#monthForExport,
#yearForExport {
  position: fixed !important;
}
.dygraph-title {
  margin: auto;
  line-height: normal;
  height: 1.2rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 3rem;
  color: #7b868d;
  top: -0.5rem;
  position: relative;
}
#legend_monthly {
  float: left;
  width: 7rem;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}
#legend_yearly {
  float: left;
  width: 9.5rem;
  font-size: 0.9rem;
  padding-left: 1rem;
}
.monthly-graph > div,
.yearly-graph > div {
  width: 100%;
  height: 100%;
}
.monthly-graph > div.helptext,
.yearly-graph > div.helptext {
  top: 25px;
  position: absolute;
  top: 30px;
  left: 60px;
  z-index: 1;
  width: auto;
  height: auto;
}
.container.graphic.closed-graph #legend_monthly,
.container.graphic.closed-graph #legend_yearly {
  display: none;
}
.dygraph-legend {
  width: auto !important;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background-color: rgba(255, 255, 255, 0.7) !important;
  padding: 4px;
  border: 1px solid #888;
  border-radius: 10px;
  box-shadow: 4px 4px 4px #888;
  pointer-events: none;
}

/*.dygraph-legend > span {
  display: none;
}*/
.dygraph-legend > span.highlight {
  font-weight: 900;
}

/*///////////////////  Project Partners  ///////////////////*/
main.project-partners {
  overflow: auto;
  padding-bottom: 2rem;
}
.row-partners {
  margin-top: 2rem;
}
.card {
  display: inline-block;
  width: 100%;
}
img.img-partners {
  width: 17%;
  float: left;
  padding: 1.25rem 0 1.25rem 1.25rem;
}
.card-body.body-partners {
  width: 80%;
  float: right;
}
/*///////////////////  MEDIA QUERIES  ///////////////////*/
@media (min-width: 1500px) {
  .navbar-center > .nav-link,
  .navbar-center > .nav-item {
    margin: 0 3rem;
  }
}
@media (max-width: 1600px) {
  .header .dropdown {
    max-width: 16.66%;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 0.9rem;
  }
  .brio-header .navbar .navbar-brand {
    margin-right: 1rem;
  }
}

@media (min-width: 1230px) {
  .navbar-center > .nav-link,
  .navbar-center > .nav-item {
    margin: 0 2rem;
  }
}
@media (min-width: 998px) {
  .navbar-center > .nav-link,
  .navbar-center > .nav-item {
    margin: 0 1rem;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    margin: auto;
    top: 15%;
  }
}

@media (max-width: 1350px) {
  .container.graphic #title_graphs {
    font-size: 1.1rem;
    width: calc(100% - 10rem);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 1280px) {
  .container-header .header-regions .nav.nav-pills .nav-item {
    white-space: nowrap;
    max-width: 11.11%;
  }
  .header-regions .nav.nav-pills .nav-item .nav-link:not(.global-button) {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .navbar-dark .navbar-right.navbar-nav .nav-link.meteofrance-logo {
    margin: 0.4rem 1rem;
  }
  .container.graphic #title_graphs {
    padding-left: 1rem;
  }
}
@media (max-width: 1230px) {
  .navbar-dark .navbar-right.navbar-nav .nav-link.meteofrance-logo {
    margin: 0.4rem 0rem;
  }
  .navbar-dark .navbar-nav .nav-link {
    margin: 0 0.5rem;
  }
  .lang-button.dropdown {
    margin-left: 0.5rem;
  }
}
@media (max-width: 998px) {
  .navbar-collapse {
    background-color: rgb(6, 54, 76);
    height: calc(100vh - 5rem);
    display: inline-block;
    position: absolute;
    overflow: hidden;
    width: 18rem;
    top: 5rem;
    right: 0;
    padding: 0;
    z-index: 10000;
    margin-right: -18rem;
    transition: margin-right 0.35s ease;
  }
  .navbar-collapse.show {
    background-color: rgb(6, 54, 76);
    height: calc(100vh - 5rem);
    display: inline-block;
    position: absolute;
    overflow: hidden;
    width: 18rem;
    top: 5rem;
    right: 0;
    padding: 0;
    z-index: 10000;
    margin-right: 0;
    transition: margin-right 0.35s ease;
  }
  .navbar-collapse .navbar-nav > .nav-item > .nav-link {
    margin: 0;
    padding: 0.5rem 2.2rem;
  }
  .navbar-dark .navbar-nav .nav-link.dropdown-toggle {
    cursor: default;
  }
  .navbar-dark .navbar-nav .nav-link.dropdown-toggle:focus,
  .navbar-dark .navbar-nav .nav-link.dropdown-toggle:hover {
    color: #ffffff;
  }
  .navbar-dark .navbar-nav .active .nav-link.dropdown-toggle:focus,
  .navbar-dark .navbar-nav .active .nav-link.dropdown-toggle:hover {
    color: #aeedf1;
  }
  .navbar-collapse .navbar-nav > .nav-link {
    margin: 0.33px 0;
    padding: 0.5rem 2.2rem;
  }
  .navbar-collapse .navbar-nav > .nav-item {
    margin: 0;
  }
  .navbar-collapse .navbar-center > .nav-item .dropdown-menu {
    color: #ffffff;
    background-color: #06364c;
    border: none;
    display: block;
  }
  .navbar-collapse .navbar-center > .nav-item .dropdown-menu * {
    font-size: 0.9rem;
  }
  .navbar-center > .nav-item.dropdown .nav-link::after {
    display: none;
  }
  .dropdown-divider {
    border: none;
  }
  .navbar-collapse .navbar-center > .nav-item .dropdown-item,
  .navbar-collapse .navbar-center > .nav-item .dropdown-item-text {
    color: #ffffff;
    padding-left: 4rem;
  }
  .navbar-dark .navbar-nav .nav-link.disabled,
  .navbar-collapse .navbar-center > .nav-item .dropdown-item[disabled],
  .navbar-collapse .navbar-center > .nav-item .dropdown-item-text[disabled] {
    color: rgba(255, 255, 255, 0.15);
    cursor: default;
  }
  .navbar-collapse .navbar-center > .nav-item .dropdown-item:focus,
  .navbar-collapse .navbar-center > .nav-item .dropdown-item:hover,
  .navbar-collapse .navbar-center > .nav-item .dropdown-item:active {
    background-color: #06364c;
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-collapse .navbar-center > .nav-item .dropdown-item.active {
    background-color: #06364c;
    color: #76b5c8;
    border-radius: 0;
  }
  .navbar-nav.navbar-right {
    flex-direction: row;
    margin-top: 2rem;
  }
  .navbar-collapse .navbar-nav.navbar-right > a.nav-link {
    height: 5rem;
    width: 50%;
    float: left;
    padding: 0;
    text-align: center;
  }
  img.img-partners {
    width: 50%;
    float: none;
    margin: auto;
    display: block;
  }
  .card-body.body-partners {
    width: 100%;
    float: none;
  }
}
/*Legends*/
@media (max-height: 740px) {
  header {
    height: 4rem;
  }
  .navbar-collapse,
  .navbar-collapse.show {
    /*height: calc(100vh - 4rem);*/
    top: 4rem;
  }
  .map-container .legend .colors div,
  .map-container .legend .texts div {
    height: 0.8rem;
  }
  .variable .dropdown-menu.show .dropdown-cat > .dropdown-menu {
    transform: none !important;
    inset: initial !important;
    margin-left: 100% !important;
    margin-top: -70% !important;
  }
  .header
    .variable
    .dropdown-menu.show
    .dropdown-cat:last-child
    > .dropdown-menu {
    margin-top: -85% !important;
  }
  .variable .dropdown-menu.show .dropdown-cat > .btn-success.dropdown-toggle {
    position: relative;
  }
}
@media (max-height: 640px) {
  .map-container .legend .colors div,
  .map-container .legend .texts div {
    height: 0.75rem;
  }
  .map-container .legend .texts {
    font-size: 0.6rem;
  }
}
@media (max-height: 500px) {
  .variable .dropdown-menu.show .dropdown-cat > .dropdown-menu {
    bottom: -1px !important;
  }
}
